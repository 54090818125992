import { request, createParams } from '../utils-tools/request';
import { Local } from '../utils/local';
const domain = window.location.host.indexOf('localhost') == -1 ? window.location.origin : 'http://ryyzs.dev-s.yunduoketang.cn';
const commonPath = '/saas-business'; // 获取flag值

export async function GET_XNY_SIZE() {
  let response = await request(createParams(`${commonPath}/v1/resource/getSpaceSize?domain=${domain}`, 'POST'));
  return response;
} //获取七牛token

export async function GET_XNY_TOKEN() {
  let response = await request(createParams(`${commonPath}/v1/resource/get/qn/token?domain=${domain}`, 'GET'));
  return response;
} // 根据key hash 获取 

export async function GET_XNY_KEY_HASH(fileName, fileType, sourcePath, sourceSize) {
  let response = await request(createParams(`${commonPath}/front/question/saveResource/${Local.get('schoolId')}?domain=${domain}`, 'POST', {
    fileName,
    fileType,
    sourcePath,
    sourceSize
  }));
  return response;
}