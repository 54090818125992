/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-08 10:10:21
 */
import log from './utils-tools/log';
import dvaApp from './dvaApp';
import App from './app';
dvaApp.router(App);
dvaApp.start('#root'); // import WebSocket from '@/utils/webSocket/wsMiddle';
// import WebSocketConfig from '@/utils/webSocket/wsConfig';
// import WebSocketProxy from "@/utils/webSocket";
// 注册WebSocket
// WebSocketConfig && WebSocketConfig.useWebSocket && WebSocket.install(WebSocketConfig.webSocketList);
//  // 启动WebSocket
// WebSocket.general();
// WebSocketProxy.install('general');

window.log = log;