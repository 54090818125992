import React, { PureComponent } from 'react';
import { Input } from 'antd';
import './index.less';

class Search extends PureComponent {
  getSearch(value) {
    this.props.getSearchValue(value);
  }

  render() {
    const {
      placeholder,
      width
    } = this.props;
    console.log(this.props);
    const {
      Search
    } = Input;
    return <div data-inspector-line="14" data-inspector-column="12" data-inspector-relative-path="src\\common\\search\\index.com.jsx" className="search_box" style={{
      width: `${width}px`
    }}>
                <Search data-inspector-line="15" data-inspector-column="16" data-inspector-relative-path="src\\common\\search\\index.com.jsx" placeholder={placeholder} onSearch={value => this.getSearch(value)} style={{
        width: width
      }} />
            </div>;
  }

}

Search.defaultProps = {
  width: '200',
  placeholder: '输入关键词搜索'
};
export default Search;