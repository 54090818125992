export const Local = {
  get(key) {
    let value = '';

    try {
      value = localStorage.getItem(key);
    } catch (ex) {
      // ios safari 无痕模式下，直接使用 localStorage.setItem 会报错
      console.error('localStorage.getItem报错, ', ex.message);
    } finally {
      return value;
    }
  },

  set(key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (ex) {
      console.error('localStorage.setItem报错, ', ex.message);
    }
  },

  remove(key) {
    try {
      localStorage.removeItem(key);
    } catch (ex) {
      console.error('localStorage.setItem报错, ', ex.message);
    }
  },

  clear() {
    try {
      localStorage.clear();
    } catch (ex) {
      console.error('localStorage.setItem报错, ', ex.message);
    }
  }

};