/*
 * @Author: 张松涛 
 * @Date: 2020-09-25 10:21:02 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-12-15 15:08:01
 * @props [defaultValue] { number }  分数
 * @props [size] { number } 大小
 */
import React, { Component, CommonUI } from 'react';
import './index.less';
import { Rate } from 'antd';
export default class Star extends Component {
  static defaultProps = {
    defaultValue: 0,
    size: 12
  };

  render() {
    const {
      defaultValue,
      size
    } = this.props;
    return <div data-inspector-line="22" data-inspector-column="12" data-inspector-relative-path="src\\common\\star\\index.com.jsx" className="star_box">
                <Rate data-inspector-line="23" data-inspector-column="16" data-inspector-relative-path="src\\common\\star\\index.com.jsx" disabled allowHalf defaultValue={defaultValue} style={{
        fontSize: `${size}px`,
        color: '#FFB033'
      }} />
            </div>;
  }

}