/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors  : zhongxue
 * @LastEditTime : 2020-09-07 10:00:00
 */
import React from 'react';
import { HashRouter as Router } from 'dva/router';
import routes from './routes';
import RouterView from './router-view';
export default function RootRouter() {
  return <Router data-inspector-line="15" data-inspector-column="9" data-inspector-relative-path="src\\router\\index.jsx">
    <RouterView data-inspector-line="16" data-inspector-column="4" data-inspector-relative-path="src\\router\\index.jsx" routes={routes} />
  </Router>;
}