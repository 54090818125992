import React, { PureComponent } from 'react';
import { Upload, message, Button, Icon } from 'antd';
import { UPLAODFILES } from '../../services/teacher';

class AliUpload extends PureComponent {
  state = {
    OSSData: {}
  };
  init = async () => {
    const OSSData = await this.mockGetOSSData();
    this.setState({
      OSSData
    });
  };
  mockGetOSSData = async () => {
    // return {
    // 	dir: 'user-dir/',
    // 	expire: '1577811661',
    // 	host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
    // 	accessId: 'c2hhb2RhaG9uZw==',
    // 	policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
    // 	signature: 'ZGFob25nc2hhbw==',
    // }
    let res = await UPLAODFILES();
    return { ...res.data.data
    };
  };
  onChange = ({
    fileList
  }) => {
    const {
      form,
      cityKeys
    } = this.props;

    if (cityKeys) {
      fileList = fileList.slice(-2);
      form.setFieldsValue({
        [cityKeys]: [...fileList]
      });
    }
  };
  onRemove = file => {
    const {
      form,
      cityKeys
    } = this.props;
    const files = form.getFieldValue(cityKeys) && form.getFieldValue(cityKeys).filter(v => v.url !== file.url);

    if (cityKeys) {
      form.setFieldsValue({
        [cityKeys]: files
      });
    }
  };
  transformFile = file => {
    const {
      OSSData
    } = this.state;
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = Date.now() + suffix;
    file.url = OSSData.dir + filename;
    return file;
  };
  getExtraData = file => {
    const {
      OSSData
    } = this.state;
    return {
      key: file.url,
      OSSAccessKeyId: OSSData.accessId,
      policy: OSSData.policy,
      Signature: OSSData.signature
    };
  };
  beforeUpload = async () => {
    const {
      OSSData
    } = this.state;
    const expire = OSSData.expire * 1000;

    if (expire < Date.now()) {
      await this.init();
    }

    return true;
  };

  async componentDidMount() {
    await this.init();
  }

  render() {
    return <Upload data-inspector-line="84" data-inspector-column="3" data-inspector-relative-path="src\\common\\AliUpload\\index.com.jsx" name='file' multiple={false} action={this.state.OSSData.host} onChange={this.onChange} onRemove={this.onRemove} transformFile={this.transformFile} data={this.getExtraData} beforeUpload={this.beforeUpload}>
				<Button data-inspector-line="94" data-inspector-column="4" data-inspector-relative-path="src\\common\\AliUpload\\index.com.jsx"><Icon data-inspector-line="94" data-inspector-column="12" data-inspector-relative-path="src\\common\\AliUpload\\index.com.jsx" type="upload" /> 上传文件</Button>
			</Upload>;
  }

}

AliUpload.defaultProps = {
  onChange: value => log(value)
};
export default AliUpload;