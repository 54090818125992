/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-24 10:22:23
 */
import dva from 'dva';
import history from './router/history';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const persistConfig = {
  // redux持久化配置
  key: 'root',
  storage: storage,
  blacklist: ['test', 'layout', 'answers', 'response', 'askquestion', 'course'] //这里的数据不持久化,可以根据需要配置

}; // 生成dva应用，配置一些hooks

const app = dva({
  history,

  onError(e) {
    // 捕捉effect中执行的报错
    // subscription中通过done触发的错误
    console.log(e);
  },

  // 用于给redux添加中间件
  // fn || fn[]  fn : (store) => (next) => (action) => {}
  onAction(store) {
    return next => action => {
      next(action);
    };
  },

  // state改变时触发
  onStateChange(state) {// 可监听state状态的变化
  },

  onReducer(reducer) {
    const persistedReducer = persistReducer(persistConfig, reducer);
    return persistedReducer;
  }

});

window.onload = () => persistStore(app._store);

export default app;