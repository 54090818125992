import React, { PureComponent, CommonUI } from 'react';
import { Upload, Icon, message, Button, Modal } from 'antd';
import { GET_XNY_SIZE, GET_XNY_TOKEN, GET_XNY_KEY_HASH } from '../../services/qiniuYun';
import { guid } from '@/utils/validate';
import { Decrypt } from '@/utils/encrypt';
import moment from 'moment';
import './index.less';
const {
  confirm
} = Modal;

class DragUpload extends PureComponent {
  state = {
    token: '',
    URL: '',
    isUpload: true,
    num: 1,
    showFilesName: ''
  };
  initSize = async () => {
    let res = await GET_XNY_SIZE();

    if (!res.data.data || !res.data.data.flag) {
      log(`上传失败，原因：`, res);
      return false;
    }

    let tokenRes = await GET_XNY_TOKEN();
    this.setState({
      token: tokenRes.data.data
    });
    log(`上传成功，数据：`, tokenRes);
    return true;
  };

  async componentDidMount() {
    let isUpload = await this.initSize();
    this.setState({
      isUpload
    });
  }

  render() {
    const {
      token,
      showFilesName
    } = this.state;
    const {
      form,
      cityKeys
    } = this.props;
    const accept = ".doc,.xls,.txt,.ppt,.docx,.xlsx,.pptx,.jpg,.png,.gif,.zip,.pdf";
    return <div data-inspector-line="41" data-inspector-column="3" data-inspector-relative-path="src\\common\\xnYUpload\\index.com.jsx">
			{showFilesName === '' ? <Upload data-inspector-line="44" data-inspector-column="4" data-inspector-relative-path="src\\common\\xnYUpload\\index.com.jsx" name="file" accept={accept} // action = "https://upload.qiniu.com/"
      action="https://up.qbox.me/" showUploadList={false} data={file => {
        const suffix = file.name.split('.')[1];
        const companyId = localStorage.getItem('userInfo') ? JSON.parse(Decrypt(localStorage.getItem('userInfo'))).companyId : "";
        const date = moment(new Date()).format('YYYY-MM-DD');
        return {
          token,
          key: `${companyId}/${date}/teacherfile/${guid()}.${suffix}`,
          name: file.name
        };
      }} onChange={async info => {
        const {
          isUpload
        } = this.state;

        if (isUpload) {
          const {
            status
          } = info.file;
          const fileName = info.file.name;
          const fileType = fileName.split('.')[1];

          if (accept.indexOf(fileType) === -1) {
            message.error(`文件格式不支持，请重新上传`);
            this.setState({
              showFilesName: '',
              URL: ''
            });
            form.setFieldsValue({
              [cityKeys]: ''
            });
          } else {
            if (status !== 'uploading') {
              const sourcePath = info.file.response.key;
              const sourceSize = info.file.size;
              let resData = await GET_XNY_KEY_HASH(fileName, fileType, sourcePath, sourceSize);

              if (resData.data.code === '1') {
                this.setState({
                  URL: resData.data.data.obj.id,
                  showFilesName: info.file.name
                });
                form.setFieldsValue({
                  [cityKeys]: resData.data.data.obj.id
                });
              }
            }

            if (status === 'done') {
              message.success(`${info.file.name}上传成功`);
            }

            if (status === 'error') {
              message.error(`${info.file.name}文件上传失败`);
              this.setState({
                showFilesName: '',
                URL: ''
              });
              form.setFieldsValue({
                [cityKeys]: ''
              });
            }
          }
        } else {
          message.error(`空间不足，请联系管理员`);
        }
      }}>
					<Button data-inspector-line="97" data-inspector-column="5" data-inspector-relative-path="src\\common\\xnYUpload\\index.com.jsx"><Icon data-inspector-line="97" data-inspector-column="13" data-inspector-relative-path="src\\common\\xnYUpload\\index.com.jsx" type="upload" /> 上传资料</Button>		
					<p data-inspector-line="98" data-inspector-column="5" data-inspector-relative-path="src\\common\\xnYUpload\\index.com.jsx" className="uploadTips">支持附件类型：doc、xls、txt、ppt、docx、xlsx、pptx、jpg、png、gif、zip</p>
				</Upload> : <div data-inspector-line="100" data-inspector-column="5" data-inspector-relative-path="src\\common\\xnYUpload\\index.com.jsx" className="nameShow">
					<b data-inspector-line="101" data-inspector-column="5" data-inspector-relative-path="src\\common\\xnYUpload\\index.com.jsx">{showFilesName}</b>
					<CommonUI.Icon data-inspector-line="102" data-inspector-column="5" data-inspector-relative-path="src\\common\\xnYUpload\\index.com.jsx" name="guanbi1" color="#D3D6D8" onClick={() => {
          confirm({
            title: '提示',
            content: '确定要删除当前上传的文件吗？',
            onOk: () => {
              this.setState({
                showFilesName: '',
                URL: ''
              });
              form.setFieldsValue({
                [cityKeys]: ''
              });
            }
          });
        }} />
				</div>}
			</div>;
  }

}

DragUpload.defaultProps = {
  onChange: value => log(value)
};
export default DragUpload;