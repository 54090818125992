/*
 * @Author: 张松涛
 * @Date: 2020-09-09 17:45:40 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-09-09 18:11:06
 * 数据格式
 * const routes = [
        {
        path: '#/teachers',
        name: '教师',
        },
        {
        path: '',
        name: '教师详情',
        }
    ];
 */
import React, { Component } from 'react';
import { Breadcrumb } from 'antd';
import './index.less';
export default class PathNav extends Component {
  render() {
    const {
      routes
    } = this.props;
    return <div data-inspector-line="26" data-inspector-column="12" data-inspector-relative-path="src\\common\\pathNav\\index.com.jsx" className="nav_box">
                <Breadcrumb data-inspector-line="27" data-inspector-column="16" data-inspector-relative-path="src\\common\\pathNav\\index.com.jsx">
                {!!routes ? routes.map(item => {
          return item.path ? <Breadcrumb.Item data-inspector-line="32" data-inspector-column="28" data-inspector-relative-path="src\\common\\pathNav\\index.com.jsx" key={item.name}>
                                <a data-inspector-line="33" data-inspector-column="32" data-inspector-relative-path="src\\common\\pathNav\\index.com.jsx" href={item.path}>{item.name}</a>
                            </Breadcrumb.Item> : <Breadcrumb.Item data-inspector-line="35" data-inspector-column="28" data-inspector-relative-path="src\\common\\pathNav\\index.com.jsx" key={item.name}>
                                {item.name}
                            </Breadcrumb.Item>;
        }) : null}  
                </Breadcrumb>              
            </div>;
  }

}