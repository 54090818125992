/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors  : zhongxue
 * @LastEditTime : 2020-09-07 10:00:00
 */
const {
  createHashHistory
} = require("history");

export default createHashHistory();