/*
 * @Author: 张松涛
 * @Date: 2020-09-12 18:17:25 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-10-10 15:44:27
 * @font 按钮展示的名字
 * @spinning 显示按钮还是动画
 * @name 动画下面的名字
 */
import React, { PureComponent } from 'react';
import { Spin } from 'antd';
import './index.less';
import { Button } from 'antd';

class Loading extends PureComponent {
  getMore() {
    this.props.getMoreData();
  }

  render() {
    const {
      name,
      spinning,
      font
    } = this.props;
    return <div data-inspector-line="22" data-inspector-column="12" data-inspector-relative-path="src\\common\\loading\\index.com.jsx" className="loading_box">
                {spinning ? <Spin data-inspector-line="25" data-inspector-column="20" data-inspector-relative-path="src\\common\\loading\\index.com.jsx" tip={name} /> : <Button data-inspector-line="26" data-inspector-column="20" data-inspector-relative-path="src\\common\\loading\\index.com.jsx" className='more' onClick={() => {
        this.getMore();
      }}>{font}</Button>}
            </div>;
  }

}

Loading.defaultProps = {
  more: false,
  font: '加载更多',
  spinning: false,
  name: '加载更多...'
};
export default Loading;