/*
 * @Author: 张松涛 
 * @Date: 2020-09-12 18:15:20 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-10-13 21:53:33
 * btn: [
            {
                name:'全部话题',
                id:0
            },
            {
                name:'热门',
                id:1
            },
            {
                name:'精华',
                id:2
            }
        ]
* width: '300px'
* size: '14px'
* btnIndex: callback          
 */
import React, { PureComponent } from 'react';
import css from './index.module.less';

class Tabs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      chooseId: 0
    };
  }

  componentDidMount() {
    this.getIndex(this.props.id ? this.props.id : 0);
  }

  getIndex(id) {
    this.setState({
      chooseId: id
    });
    let demo = this.refs[`demo_${id}`];
    let ani = this.refs.animate_i;
    let cliWidth = demo.clientWidth;
    let offWidth = demo.offsetLeft;
    ani.style.transition = 'all 0.2s';
    ani.style.marginLeft = (cliWidth - 20) / 2 + offWidth + 'px';
    this.props.btnIndex(id);
  }

  render() {
    const {
      btn,
      width,
      size
    } = this.props;
    const {
      chooseId
    } = this.state;
    return <div data-inspector-line="53" data-inspector-column="12" data-inspector-relative-path="src\\common\\tabs\\index.com.jsx" className={css.Tabs_box} style={{
      width: `${width}`
    }}>
                {btn ? btn.map(item => {
        return <button data-inspector-line="57" data-inspector-column="28" data-inspector-relative-path="src\\common\\tabs\\index.com.jsx" key={item.id} onClick={() => this.getIndex(item.id)} ref={`demo_${item.id}`} style={{
          color: `${chooseId === item.id ? `var(--themeColor)` : `#2A2A2A`}`,
          fontWeight: `${chooseId === item.id ? `bold` : ``}`,
          fontSize: `${size}`
        }}>
                                {item.name}{item.number ? `(${item.number})` : null}
                            </button>;
      }) : null}
                <i data-inspector-line="72" data-inspector-column="16" data-inspector-relative-path="src\\common\\tabs\\index.com.jsx" ref='animate_i'></i>
            </div>;
  }

}

export default Tabs;