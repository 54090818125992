/*
 * @Author: zhongxue 
 * @Date: 2020-09-07 20:55:23 
 * @Last Modified by: zhongxue
 * @Last Modified time: 2020-10-09 18:31:41
 */
import React from 'react';
import classNames from 'classnames'; // import './css/iconfont.css'

import { colors } from './index.module.less';
/**
 * @props [name] { string }  名称
 * @props [color] { string } 颜色
 * @props [size] { number }  大小
 */

let Icon = props => {
  const {
    name,
    size,
    color
  } = props;

  if (color === 'themeColor') {
    return <span data-inspector-line="23" data-inspector-column="15" data-inspector-relative-path="src\\common\\Icon\\index.com.jsx" className={classNames('iconfont', 'icon' + name, colors)} onClick={props.onClick} style={{
      fontSize: size + 'px'
    }}>
        </span>;
  }

  return <span data-inspector-line="29" data-inspector-column="11" data-inspector-relative-path="src\\common\\Icon\\index.com.jsx" className={classNames('iconfont', 'icon' + name)} onClick={props.onClick} style={{
    fontSize: size + 'px',
    color
  }}>
    </span>;
};

Icon.defaultProps = {
  name: 'gengduo-23',
  color: '#000',
  size: 14,
  onClick: () => {}
};
export default Icon;