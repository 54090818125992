import React, { Component } from 'react';
import classNames from 'classnames';
import Swiper from 'swiper';
import './index.less';
import 'swiper/dist/css/swiper.min.css';

class Swipers extends Component {
  render() {
    const {
      style,
      children,
      navigation,
      pagination,
      paginationType,
      swiperStyle,
      styleNoSwiping
    } = this.props;
    return <div data-inspector-line="12" data-inspector-column="12" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" ref="swiperDom" className={classNames("swiper-container", style, styleNoSwiping)} style={swiperStyle}>
                <div data-inspector-line="13" data-inspector-column="16" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" className="swiper-wrapper">
                    {children.length ? children.map((item, key) => {
          return <div data-inspector-line="16" data-inspector-column="35" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" key={key} className={classNames("swiper-slide")}>
                                {item}
                            </div>;
        }) : children}
                </div>
                {pagination ? <Pagination data-inspector-line="22" data-inspector-column="30" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" style={paginationType} /> : null}
                {navigation ? <div data-inspector-line="23" data-inspector-column="30" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" onClick={this.props.DivNext}><BtnNext data-inspector-line="23" data-inspector-column="64" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" style={paginationType} /></div> : null}
                {navigation ? <div data-inspector-line="24" data-inspector-column="30" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" onClick={this.props.DivPrev}><BtnPrev data-inspector-line="24" data-inspector-column="64" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" style={paginationType} /></div> : null}
            </div>;
  }

  newSwipersMethod = () => {
    const SwiperSetting = {};
    const {
      pagination,
      navigation,
      slidesPerColumn,
      mousewheel,
      slidesPerView,
      paginationType,
      loop,
      renderCustom,
      autoplay,
      pagCustomFn
    } = this.props; // 默认关闭环路

    if (loop) {
      SwiperSetting.loop = SwiperSetting.loopFillGroupWithBlank = loop;
    } // 开启多列效果


    if (slidesPerView > 1) {
      SwiperSetting.slidesPerView = SwiperSetting.slidesPerGroup = slidesPerView;
    } // 开启多行效果


    if (slidesPerColumn > 1) {
      SwiperSetting.slidesPerColumn = slidesPerColumn; // SwiperSetting.spaceBetween = 30;
    } // 存在分页器


    if (pagination) {
      SwiperSetting.pagination = {
        el: '.swiper-pagination',
        clickable: true,
        type: paginationType,
        renderCustom
      };
    } // 存在左右按钮


    if (navigation) {
      SwiperSetting.navigation = {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      };
    } // 自动切换


    if (autoplay) {
      SwiperSetting.autoplay = autoplay;
    }

    let swiper = new Swiper(this.refs.swiperDom, SwiperSetting);
    this.swiper = swiper;
    pagCustomFn(swiper);
  };

  componentDidMount() {
    this.newSwipersMethod();
    this.props.sendSwiper(this.swiper);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.children.length !== nextProps.children.length) {
      this.newSwipersMethod();
    }

    return true;
  }

}

Swipers.defaultProps = {
  children: [],
  navigation: false,
  pagination: true,
  paginationType: 'bullets',
  slidesPerView: 1,
  slidesPerColumn: 1,
  loop: false,
  autoplay: false,
  swiperStyle: {},
  renderCustom: () => {},
  pagCustomFn: () => {},
  DivNext: () => {
    log(`点击了下一页`);
  },
  DivPrev: () => {
    log(`点击了上一页`);
  },
  initSlider: true,
  sendSwiper: () => {},
  styleNoSwiping: ''
};

const BtnNext = props => <div data-inspector-line="103" data-inspector-column="25" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" className={classNames("swiper-button-next", 'btn-next-' + props.style)}></div>;

const BtnPrev = props => <div data-inspector-line="104" data-inspector-column="25" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" className={classNames("swiper-button-prev", 'btn-prev-' + props.style)}></div>;

const Pagination = props => <div data-inspector-line="105" data-inspector-column="28" data-inspector-relative-path="src\\common\\Swipers\\index.com.jsx" className={classNames("swiper-pagination", props.style)}></div>;

export default Swipers;